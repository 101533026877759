<template>
    <div class="form">
        <form-row-input label="Название" v-model="name" />
        <form-row-input label="Код 1С" v-model="code" />

        <form-row-select label="Тип упаковки" :items="types" v-model="pack_type_id" />

        <form-row-input label="Вместимость" v-model="capacity" />
        <form-row-input label="Вес" v-model="weight" />
        <form-row-input label="Размер" v-model="size" />
        <form-row-input label="Цена" v-model="price" />
        
        <div class="form__row">
            <div class="form__label">Символ года:</div>
            <div class="form__input"><input type="text"></div>
        </div>
        <div class="form__row">
            <div class="form__label">Якутская:</div>
            <div class="form__input"><input type="text"></div>
        </div>
        <div class="form__row">
            <div class="form__label">В инд наборах:</div>
            <div class="form__input"><input type="text"></div>
        </div>
        <div class="form__row">
            <div class="form__label">Метка:</div>
            <div class="form__input"><input type="text"></div>
        </div>
        <div class="form__row">
            <div class="form__label"></div>
            <div class="form__input">
                <button>Сохранить</button>
                <router-link to="/packs">
                <button>Отмена</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import FormRow from '@/components/UI/FormRow.vue'
import FormRowInput from '@/components/UI/FormRowInput.vue'
import FormRowSelect from '@/components/UI/FormRowSelect.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'PacksNew',
    components: {
        FormRowInput,
        FormRowSelect
    },
    data(){
        return {
            name: null,
            pack_type_id: null,
            code: null,
            image: null,
            capacity: null,
            weight: null,
            size: null,
            price: null,
            symbol: null,
            ykt: 0,
            personal: 1,
            label_id: null,
            active: 1,
        }
    },
    computed: {
        ...mapGetters({
            types: 'GetPackTypes'
        }),
    }
}
</script>