<template>
    <div class="form__row">
        <div class="form__label">{{ label }}:</div>
        <div class="form__input">
            <select :value="modelValue" @change="changeSelect">
                <option v-for="item in items" :value="item.id" 
                :key="item.id"
                :selected="{'selected' : modelValue == item.id}"
                >{{ item.name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-row-select',
    props: {
        label: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        modelValue: [String, Number]
    },
    methods: {
        changeSelect(ev){
            this.$emit('input', ev.target.value)
        }
    }
}
</script>