<template>
    <div class="form__row">
        <div class="form__label">{{ label }}:</div>
        <div class="form__input"><slot></slot></div>
    </div>
</template>

<script>
export default {
    name: 'form-row',
    props: {
        label: {
            type: String,
            required: true
        }
    }
}
</script>