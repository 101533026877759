<template>
    <div class="form__row">
        <div class="form__label">{{ label }}:</div>
        <div class="form__input"><input type="text" :value="modelValue" @input="inputHandler"></div>
    </div>
</template>

<script>
export default {
    name: 'form-row-input',
    props: {
        label: {
            type: String,
            required: true
        },
        modelValue: [String, Number]
    },
    methods: {
        inputHandler(ev){
            this.$emit('input', ev.target.value)
        }
    }
}
</script>